import { Search } from '@jsluna/icons'
import { Button, IconButton } from '@jsluna/button'
import { ReactComponent as UserPlus } from '../../assets/icons/user-plus-solid.svg'
import SitesApi from '../../api/sitesApi'
import FormAlerts from "../Common/FormAlerts";
import { Pagination } from '@jsluna/pagination';
import { AutocompleteField } from "@jsluna/react";
import WorkordersList from '../Common/WorkordersList'
import { useApiClient } from '../../context/AppContext'
import React, { useRef, useState, useEffect } from 'react'
import riskWorkordersApi from '../../api/riskWorkordersApi'
import incidentManagementApi from '../../api/IncidentManagementApi'
import { FilledButton, OutlinedButton, TextInputField, TextInput } from '@jsluna/react'
import { Workorders, RiskWorkorderFilter, RiskWorkorderModel, } from '../../types/Entities/Workorders'
import IncidentList from '../Common/IncidentList'
import { GridWrapper, GridItem, SearchInput, CheckboxField, FormGroup, SelectField, Card } from '@jsluna/react'
import ConfirmationModal from '../Common/ConfirmationModal';
import { IncidentManagements, incidentStatusCount } from '../../types/Entities/IncidentManagement';


const IncidentManagement = (props: { status: Boolean }) => {
  const recordsPerPage = 20;
  const apiClient = useApiClient()
  const [sortBy, setSortBy] = useState('')
  const [loader, setLoader] = useState(false)
  const [siteOptions, setSiteOptions] = useState([{}])
  const [currentPage, setCurrentPage] = React.useState(1)
  const [totalRecords, setTotalRecords] = useState<number>()
  const [incidentsStatusCount, setIncidentsStatusCount] = useState<incidentStatusCount>()
  const [addRiskWorkOrders, setAddRiskWorkOrders] = useState('')
  const [errorAlert, setErrorAlert] = useState<false | string>(false)
  const [successAlert, setSuccessAlert] = useState<false | string>(false)
  const [riskWorkorders, setRiskWorkorders] = useState([] as IncidentManagements[]);
  const [workorderStatus, setWorkorderStatus] = useState([] as Array<Array<string | Boolean>>)
  const [modalState, setModalState] = useState<"save" | "discard" | false>(false);
  const defaultFalseStatus = [["Open", false], ["Root Cause Analysis", false]]
  const defaultTrueStatus = [["Open", true], ["Root Cause Analysis", true]]
  const sortByOptions = [
    { value: 'datecreatedAscending', label: 'Date Opened Ascending' },
    { value: 'datecreatedDescending', label: 'Date Opened Descending' },
    { value: 'timerAscending', label: 'Update Timer Ascending' },
    { value: 'timerDescending', label: 'Update Timer Descending' },
    { value: 'statusAscending', label: ' Open Incidents first' },
    { value: 'statusDescending', label: 'Open Incidents last' },
  ]
  const handleScrollModal = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto'
    })
  }
  const defaultFilter = {
    SearchByWorkOrderNumber: "",
    SearchBySiteName: "",
    SearchByStatus: ["Open", "Root Cause Analysis"],
    SortColumn: "datecreated",
    SortColumnDirection: "desc",
    StartIndex: 1,
    PageSize: recordsPerPage
  }
  const [filter, setFilter] = useState<RiskWorkorderFilter>(defaultFilter)
  const scrollToTop = () => document.querySelector('div.ln-o-container.content')?.scrollTo(0, 0)


  const [incidenttWorkorders, setIncidentWorkorders] = useState<IncidentManagements[]>([])




  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchDataFor_N_Seconds()
    }, 200000);
    return () => clearInterval(interval);

  }, []);
  

  const getFilterFromLocalStorage = (filterData: string) => {
    return filterData ? JSON.parse(filterData) : null;
  };

  const fetchDataFor_N_Seconds = () => {
    const filterData = localStorage.getItem('incidentManagementFilter') as string;
    const filterdDataObject = getFilterFromLocalStorage(filterData)
    if (filterdDataObject) {
      setCurrentPage(filterdDataObject.StartIndex)
      for (var i = 0; i < filterdDataObject.SearchByStatus.length; i++) {
        const index = defaultFalseStatus.findIndex(item => item[0] === filterdDataObject.SearchByStatus[i]);
        defaultFalseStatus[index][1] = true
      }
      setWorkorderStatus(defaultFalseStatus)
      if (filterdDataObject.SortColumn === 'datecreated') {
        setSortBy(filterdDataObject.SortColumnDirection === 'asc' ? 'datecreatedAscending' : 'datecreatedDescending')
      } else if (filterdDataObject.SortColumn === 'timer') {
        setSortBy(filterdDataObject.SortColumnDirection === 'asc' ? 'timerAscending' : 'timerDescending')
      } else{
        setSortBy(filterdDataObject.SortColumnDirection === 'asc' ? 'statusAscending' : 'statusDescending')
      }
      setFilter(filterdDataObject)
      loadallriskworkorderspaging(filterdDataObject)
    } else {
      setWorkorderStatus(defaultTrueStatus)
      setFilter(defaultFilter)
      loadallriskworkorderspaging(defaultFilter)
      localStorage.setItem('incidentManagementFilter', JSON.stringify(defaultFilter))
    }
  }

  const fetchData = async () => {
    const filterData = localStorage.getItem('incidentManagementFilter') as string;
    const filterdDataObject = getFilterFromLocalStorage(filterData)
    if (!props.status && filterdDataObject) {
      setCurrentPage(filterdDataObject.StartIndex)
      for (var i = 0; i < filterdDataObject.SearchByStatus.length; i++) {
        const index = defaultFalseStatus.findIndex(item => item[0] === filterdDataObject.SearchByStatus[i]);
        defaultFalseStatus[index][1] = true
      }
      setWorkorderStatus(defaultFalseStatus)
      if (filterdDataObject.SortColumn === 'datecreated') {
        setSortBy(filterdDataObject.SortColumnDirection === 'asc' ? 'datecreatedAscending' : 'datecreatedDescending')
      } else if (filterdDataObject.SortColumn === 'timer') {
        setSortBy(filterdDataObject.SortColumnDirection === 'asc' ? 'timerAscending' : 'timerDescending')
      } else{
        setSortBy(filterdDataObject.SortColumnDirection === 'asc' ? 'statusAscending' : 'statusDescending')
      }
      setFilter(filterdDataObject)
      await loadriskwo(filterdDataObject);
    } else {
      setWorkorderStatus(defaultTrueStatus)
      setFilter(defaultFilter)
      localStorage.setItem('incidentManagementFilter', JSON.stringify(defaultFilter))
      await loadriskwo(defaultFilter)
    }
    await getsitesApi();
  }

  const getsitesApi = async () => {
    return SitesApi.getallSiteNames(apiClient)
    .then((response) => {
      setSiteOptions(response)
    })
      .catch(error => setErrorAlert('Something went wrong!'))
  }

  const loadriskwo = async (filters: RiskWorkorderFilter) => {
    setLoader(true)
    await loadallriskworkorderspaging(filters)
    setLoader(false)
    scrollToTop()
  }

  const serachFilter = async () => {
    const filterforSearch = { ...filter }
    filterforSearch.StartIndex = 1
    setFilter(filterforSearch)
    setCurrentPage(1)
    loadriskwo(filterforSearch)
    localStorage.setItem('incidentManagementFilter', JSON.stringify(filterforSearch))
  }

  const clearAlerts = () => {
    setErrorAlert(false);
    // setSuccessAlert(false);
  }

  const handleDiscardForm = () => {
    setModalState(false);
  };

  const loadallriskworkorderspaging = async (filters: RiskWorkorderFilter): Promise<RiskWorkorderModel> => {
    return incidentManagementApi
      .getallincidentspaging(apiClient, filters)
      .then((response) => {
        setRiskWorkorders(response.workOrders)
        setTotalRecords(response.recordsCount)
        setIncidentsStatusCount(response.statusCounts)
        if (!response) {
          throw new Error('Invalid response.')
        }
      }).catch((error : any) => {
        setErrorAlert('Something went wrong!')
        return error
      })
  }

  const SearchRiskworkOrders = (workorderNumber: any) => {
    const regex = /^[0-9]+$/;
    const newValue = workorderNumber;
    if (regex.test(newValue) || newValue === "") {
      setFilter((prevFilter) => ({
        ...prevFilter,
        SearchByWorkOrderNumber: newValue,
      }))
    }
  }

  const AddRiskWorkOrders = (workorderNumber: any) => {
    const regex = /^[0-9]+$/;
    const newValue = workorderNumber;
    if (regex.test(newValue) || newValue === "") {
      setAddRiskWorkOrders(workorderNumber)
    }
  }

  const CheckCheckBox = (props: any) => {
    let searchByStatusValue = filter.SearchByStatus;
    const index = workorderStatus.findIndex(item => item[0] === props.target.defaultValue);
    if (props.target.checked) {
      if (!searchByStatusValue?.includes(props.target.defaultValue)) {
        searchByStatusValue?.push(props.target.defaultValue);
        let workOrderStatusFilter = [...workorderStatus]
        workOrderStatusFilter[index] = [props.target.defaultValue, 'true']
        setWorkorderStatus(workOrderStatusFilter)
      }
    } else {
      searchByStatusValue = searchByStatusValue?.filter(status => status !== props.target.defaultValue);
      let workOrderStatusFilter = [...workorderStatus]
      workOrderStatusFilter[index] = [props.target.defaultValue, 'false']
      setWorkorderStatus(workOrderStatusFilter)
    }
    setFilter(prevFilter => ({
      ...prevFilter,
      SearchByStatus: searchByStatusValue
    }));
  }

  const selectedSite = (props: any) => {
    if(props === null){
      setFilter((prevFilter: RiskWorkorderFilter) => ({
        ...prevFilter,
        SearchBySiteName: ''
      }));
    }else{
      if(props && props.value){
        setFilter((prevFilter: RiskWorkorderFilter) => ({
          ...prevFilter,
          SearchBySiteName: props.value
        }));
      }else{
        setFilter((prevFilter: RiskWorkorderFilter) => ({
          ...prevFilter,
          SearchBySiteName: props.target.value
        }));
      }
    }
  }

  const selectSortBy = (props: any) => {
    switch (props.target.value) {
      case 'datecreatedAscending':
        setFilter(prevFilter => ({
          ...prevFilter,
          SortColumn: 'datecreated',
          SortColumnDirection: 'asc'
        }));
        setSortBy('datecreatedAscending')
        break;
      case 'datecreatedDescending':
        setFilter(prevFilter => ({
          ...prevFilter,
          SortColumn: 'datecreated',
          SortColumnDirection: 'desc'
        }));
        setSortBy('datecreatedDescending')
        break;
      case "timerAscending":
        setFilter(prevFilter => ({
          ...prevFilter,
          SortColumn: 'timer',
          SortColumnDirection: 'asc'
        }));
        setSortBy('timerAscending')
        break;
      case "timerDescending":
        setFilter(prevFilter => ({
          ...prevFilter,
          SortColumn: 'timer',
          SortColumnDirection: 'desc'
        }));
        setSortBy('timerDescending')
        break;
      case "statusAscending":
        setFilter(prevFilter => ({
          ...prevFilter,
          SortColumn: 'status',
          SortColumnDirection: 'asc'
        }));
        setSortBy('statusAscending')
        break;
      case "statusDescending":
        setFilter(prevFilter => ({
          ...prevFilter,
          SortColumn: 'status',
          SortColumnDirection: 'desc'
        }));
        setSortBy('statusDescending')
        break;
      default:
        setSortBy('')
        break;
    }
  }

  const discardFilters = async () => {
    setWorkorderStatus(defaultTrueStatus)
    setCurrentPage(1)
    setFilter(defaultFilter)
    localStorage.setItem('incidentManagementFilter', JSON.stringify(defaultFilter))
    window.location.reload()
  }

  const handleAddMajorWorkOrder = () => {
    setModalState(false)
    if (addRiskWorkOrders != '') {
      setSuccessAlert("One major Workorder Added")
    } else {
      setErrorAlert("to add major workorder input field cannot be empty")
    }

  };

  return (
    <>
      <FormAlerts
        showError={errorAlert}
        showSuccess={successAlert}
        onClose={clearAlerts}
      />
      <ConfirmationModal
        showModal={modalState}
        onClose={handleDiscardForm}
        onSave={handleAddMajorWorkOrder}
        onDiscard={handleDiscardForm}
        onComplete={handleAddMajorWorkOrder}
      />
      <GridWrapper>
        <GridItem size={{ xs: "1/1", lg: "1/4" }} >
          <div style={{display:"contents"}}
            className={"cb-c-alerts-page__filter-container--full-screen"}>
            <GridItem
              size={{ xs: "1/1", sm: "1/1" }}
              className="cb-c-alerts-page__left-panel alerts-page__filter">
              {/* <div className="cb-c-alerts-page__options">
                <div>
                  <h4>Add Workorder</h4>
                    <FormGroup name="workordernumber" label="Workorder Number">
                      <TextInputField
                        className='multiple-text-input-box'
                        name="workorder-search"
                        value={addRiskWorkOrders}
                        placeholder="e.g. 56870001"
                        onChange={(e: any) => AddRiskWorkOrders(e.target.value)}
                        disabled
                        action={
                          <Button onClick={() => setModalState('save')} disabled>
                            <UserPlus />
                          </Button>
                        }
                      /></FormGroup>
                </div>
              </div><br/><br/><br/> */}
              <div className="cb-c-alerts-page__options">
                <div>
                  <h4>Find Workorder</h4>
                  <FormGroup name="workordernumber" label="Workorder Number">
                    <SearchInput
                      name="workorder-search"
                      value={filter.SearchByWorkOrderNumber}
                      placeholder="e.g. 56870001"
                      onChange={(e: any) => SearchRiskworkOrders(e.target.value)}
                      hasAction
                      action={
                        <button onClick={() => serachFilter()}>
                          <Search />
                        </button>
                      }
                    /></FormGroup>
                  <div>
                    <GridWrapper>
                      <GridItem size="1/1">
                        <h4>Filter Workorders</h4>
                      </GridItem>
                    </GridWrapper>
                  </div>
                  <FormGroup name="workordernumber" label="Incident Status">
                    <GridWrapper name='Workorder' label='workorder'>
                        {workorderStatus &&
                          workorderStatus.map((status, index) => (
                            <>
                            <GridItem size='4/5'>
                              {" "}
                                  <ul key={index}>
                                    <CheckboxField
                                      name={status[0]}
                                      checked={status[1]}
                                      label="Field label"
                                      hideLabel
                                      placeholder="Placeholder"
                                      onChange={CheckCheckBox}
                                      options={[
                                        {
                                          value: status[0],
                                          label: status[0],
                                          defaultChecked: status[1]
                                        }
                                      ]}
                                    />
                                  </ul>
                            </GridItem>
                            <GridItem
                              size="1/5"
                              className="cb-c-alert-filter__is-active"
                              >
                                {incidentsStatusCount && (
                                  <div className="ln-c-form-group">
                                    <div className="ln-c-form-option">
                                      {/* {20} */}
                                      { index== 0 ? incidentsStatusCount?.Open : incidentsStatusCount?.RootCauseAnalysis}
                                    </div>
                                  </div>
                                )}
                            </GridItem>
                            </>
                          ))}
                      <GridItem>
                        <AutocompleteField
                          value={filter.SearchBySiteName}
                          label={'Site'}
                          defaultSelectedOption={filter.SearchBySiteName }
                          placeholder="Please select a site"
                          name={"SiteSelector"}
                          onSelect={(selectedSiteOption: any) => selectedSite(selectedSiteOption)}
                          onChange={selectedSite}
                          options={siteOptions}
                          noResultsMessage="No stores found"
                          // error={"error"}
                          /> 

                        <SelectField
                          value={sortBy}
                          label="Sort By"
                          name={"StoreSelector"}
                          // defaultSelectedOption={"select"}
                          // placeholder={'select sort by'}
                          onChange={selectSortBy}
                          options={sortByOptions}
                          noResultsMessage="No stores found"
                        />
                      </GridItem>
                    </GridWrapper>
                  </FormGroup>
                  <>
                    <GridItem size={{ xl: '1', ss: '1', default: '1/1' }} className='formInputMaxWidth'>
                      <div className='formButtonGroup'>
                        <OutlinedButton size='200px' onScroll={handleScrollModal} onClick={discardFilters} className='formDiscardButton' >Discard</OutlinedButton>
                        <FilledButton size='200px' onScroll={handleScrollModal} onClick={serachFilter} className='formSaveButton' >Search</FilledButton>
                      </div>
                    </GridItem>
                  </>
                </div>
              </div>
            </GridItem><br/><br/>
          </div>
        </GridItem>
        <GridItem
          className={"cb-c-alerts-page__right-panel"}
          size={{ xs: "1/1", lg: "3/4" }}>
            {/* <div> */}
            <GridWrapper>
            {/* {[[223, 'Total Cases Managed', 'Today' , 'last week'],[22, "Total Cases Managed", 'This Week', 'last week'],[5, "Total Cases Managed" , 'This Period', 'last period'],[44, "Average Risk Score", '', '']].map(list => (
                  <GridItem key={1} size={{ sm: '1/4' }} style={{ marginTop:"10px",  textAlign:'center' , alignItems:"center"}}>
                      <a href={'#'} target="_blank" style={{textDecoration:'none'}}>
                          <Card
                              className="cb-c-alert-list-item__drop-shadow cb-c-alert-list-item"
                              style={{ height: '20%', backgroundColor: 'orange', color: 'white',display:'flex',justifyContent:'center',alignItems:'center', textAlignLast:"center"}}
                          >
                              <div className="ln-u-display-3-fixed cb-c-alert-list-item__value cb-u-text-align-center-responsive" >
                                
                                <GridWrapper style={{alignItems:'center'}}>
                                  <GridItem style={{fontSize:'medium' }} size={{sm:'1', default: '1'}}>{list[1]}</GridItem>
                                  <GridItem style={{fontSize:'medium' }} size={{default: '5/12'}}>{list[2]}</GridItem>
                                  <GridItem style={{fontSize:'medium' }} size={{default: '2/12'}}>VS</GridItem>
                                  <GridItem style={{fontSize:'medium' }} size={{default: '5/12'}}>{list[3]}</GridItem>
                                  <GridItem style={{fontSize:'medium' }} size={{default: '5/12'}}>{list[0]}</GridItem>
                                  <GridItem style={{fontSize:'medium' }} size={{default: '2/12'}}></GridItem>
                                  <GridItem style={{fontSize:'medium' }} size={{default: '5/12'}}>{list[0]}</GridItem>
                                </GridWrapper>
                              </div>
                          </Card>
                      </a>
                  </GridItem>
                ))} */}
              </GridWrapper>
            {/* </div>
            <div> */}
          {!loader && (
            riskWorkorders.length > 0 ? riskWorkorders.map((workorder, index) => (
              <div key={workorder.id}>
                <IncidentList workorder={workorder} />
              </div>
            )) : <div>There is no data to display</div>)}
          {
            (loader === true) && (<div className='loader'></div>)
          }
          {riskWorkorders.length > 0 && !loader &&
            <Pagination
              showFirstAndLast
              showPages
              current={currentPage}
              total={totalRecords ? Math.ceil(totalRecords / recordsPerPage) : 1}
              onChange={(page: number, e: React.MouseEvent<HTMLButtonElement>) => {
                setCurrentPage(page);
                const filterforPage={...filter}
                filterforPage.StartIndex=page
                localStorage.setItem('incidentManagementFilter' , JSON.stringify(filterforPage))
                setFilter(prevFilter => ({
                  ...prevFilter,
                  StartIndex: page
                }));
                loadriskwo(filterforPage)
              }}
            />
          }
          {/* </div> */}
        </GridItem>
      </GridWrapper>
    </>
  )
}

export default IncidentManagement
