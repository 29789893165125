import React from 'react';
import { postComment, Workorders } from "../../../types/Entities/Workorders";
import { Comments } from "../../../types/Entities/IncidentManagement"

import Circle from "../../../assets/icons/Circle.svg"
import moment from "moment";

interface CommentsProps {
    comments: (postComment | Comments)[];
}

const Comment = ({ comments }: CommentsProps) => {
    return (
        <div>
            {comments.map((comment, index) => (
                <div className='workorder_updates'
                >
                    <div style={{ margin: "10px", marginLeft: "-10px" }}>
                        <div>
                            <svg height="35" width="15">
                                <line x1="2" y1="35" x2="2" y2="0"></line>
                            </svg>
                        </div>
                        <img src={Circle} alt="Circle" height="20" width="20"></img>
                        <div>
                            <svg height="100%" width="15">
                                <line x1="2" y1="100%" x2="2" y2="0"></line>
                            </svg>
                        </div>
                    </div>
                    <div
                        key={index}
                        className=''
                        style={{
                            margin: "10px",
                            marginTop: "50px",
                            width: "200%",
                            height: "50%",
                        }}
                    >
                        <div
                        >
                            <div
                                style={{
                                    padding: "1rem",
                                    border: "1px solid #d8d8d8",
                                    backgroundColor: "#d8d8d8",
                                }}
                            >
                                <span><b>{comment.commentedBy}</b> commented on{" "}
                                    {comment.commentedDate
                                        ? moment(comment.commentedDate).format("DD MMM YY,HH:mm")
                                        : ""}{" "}</span>
                                <br />
                            </div>
                            <div style={{ padding: "1rem", border: "1px solid #d8d8d8" }}>
                                <span>{comment.comments}</span>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Comment;
