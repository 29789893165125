import { Search } from '@jsluna/icons'
import SiteCareManagerApi from '../../api/siteCareMangerApi'
import FormAlerts from "../Common/FormAlerts";
import { useApiClient } from '../../context/AppContext'
import { Modal, ModalHeading } from "@jsluna/modal"
import React, { useRef, useState, useEffect } from 'react'
import { FilledButton, OutlinedButton, TextInputField, TextInput } from '@jsluna/react'
import { SiteCareManagerContactModel, SCMFilter } from '../../types/Entities/SiteCareManagerType'
import { GridWrapper, GridItem, SearchInput, CheckboxField, FormGroup, SelectField } from '@jsluna/react'


const SiteCareManagersContacts = () => {
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(false)
  const [error, setError] = useState<string | null>(null);
  const [errorAlert, setErrorAlert] = useState<false | string>(false);
  const [successAlert, setSuccessAlert] = useState<false | string>(false);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const [users, setUsers] = useState([{}])
  const [regions, setRegions] = useState([{}]);
  const [zones, setZones] = useState([{}]);
  const [siteCareManagerModel, setSiteCareManagerModel] = useState([] as SiteCareManagerContactModel[]);
  const [filterSCMModel, setFilterSCMModel] = useState([] as SiteCareManagerContactModel[]);
  const [scmfilter, setSCMFilter] = useState({} as SCMFilter);
  const apiClient = useApiClient();
  const scrollToTop = () => document.querySelector('div.ln-o-container.content')?.scrollTo(0, 0)

  useEffect(() => {
    const sitemangerdts = async () => {
      setLoader(true);
      loadsitecaremanagerdts();
      setLoader(false);
    }
    sitemangerdts();
  }, []);

  const loadsitecaremanagerdts = (): Promise<Array<SiteCareManagerContactModel>> => {
    return SiteCareManagerApi
      .getallregiondetails(apiClient)
      .then((response) => {
        setSiteCareManagerModel(response);
        setFilterSCMModel(response);

        const distnames = response.filter(
          (dts: { name: any; }, i: any, arr: any[]) => arr.findIndex((t: { name: any; }) => t.name === dts.name) === i
        );

        const distregion = response.filter(
          (dts: { region: any; }, i: any, arr: any[]) => arr.findIndex((t: { region: any; }) => t.region === dts.region) === i
        );

        const distzone = response.filter(
          (dts: { zone: any; }, i: any, arr: any[]) => arr.findIndex((t: { zone: any; }) => t.zone === dts.zone) === i
        );

        const names = (distnames.filter((x: { role: string; }) => x.role == "SCM").map((item: { name: any; }) => ({
          label: item.name,
          value: item.name
        })));

        setUsers(names.sort((a: { label: number; }, b: { label: number; }) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        }));

        let regions = (distregion.map((item: { region: any; }) => ({
          label: item.region,
          value: item.region
        })));

        regions = (regions.sort((a: { label: number; }, b: { label: number; }) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        }));

        setRegions(regions.filter((x: { label: undefined; }) => x.label != undefined));

        const zones = (distzone.map((item: { zone: any; }) => ({
          label: item.zone,
          value: item.zone
        })))

        setZones(zones.sort((a: { label: number; }, b: { label: number; }) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        }));

      })
      .catch(error => {
        console.log(error)
        setErrorAlert('Something went wrong!')
        scrollToTop()
        return error;
      })
  }

  const serachFilter = async () => {

    let sd = scmfilter;
    let data = [...siteCareManagerModel];
    if (scmfilter.name) {
      data = data.filter(x => x.name == scmfilter.name);
    }

    if (scmfilter.region) {
      data = data.filter(x => x.region == scmfilter.region);
    }

    if (scmfilter.zone) {
      data = data.filter(x => x.zone == scmfilter.zone);
    }

    setFilterSCMModel(data);

  }

  const discardFilter = async () => {
    setSCMFilter({
      name: '',
      region: '',
      zone: ''
    })
    setLoader(true);
    loadsitecaremanagerdts();
    setLoader(false);
  }

  const filterchangeevent = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const element = event.target as HTMLSelectElement;

    if (element.id == "UserSelector") {
      setSCMFilter(prevFilter => ({
        ...prevFilter,
        name: element.value
      }));
    }
    else if (element.id == "RegionSelector") {
      setSCMFilter(prevFilter => ({
        ...prevFilter,
        region: element.value
      }));
    }
    else if (element.id == "ZoneSelector") {
      setSCMFilter(prevFilter => ({
        ...prevFilter,
        zone: element.value
      }));
    }

  }

  const clearAlerts = () => {
    setErrorAlert(false);
    setSuccessAlert(false);
  };

  return (
    <>
      <FormAlerts
        showError={errorAlert}
        showSuccess={successAlert}
        onClose={clearAlerts}
      />
      <GridWrapper>
        <GridItem size={{ xs: "1/1", lg: "1/4" }} >
          <div style={{ display: 'contents' }}
            className={"cb-c-alerts-page__filter-container--full-screen"}>
            <GridItem
              size={{ xs: "1/1", sm: "1/1" }}
              className="cb-c-alerts-page__left-panel alerts-page__filter">
              <div className="cb-c-alerts-page__options">
                <div>
                  <div>
                    <GridWrapper>
                      <GridItem size="1/1">
                        <h3>Filter Contacts</h3>
                      </GridItem>
                    </GridWrapper>
                  </div>
                  <FormGroup>
                    <GridWrapper>
                      <GridItem>
                        <SelectField
                          value={scmfilter.name}
                          name="UserSelector"
                          label="SCM Names"
                          placeholder={"All SCM"}
                          noResultsMessage="No data found"
                          options={users}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => filterchangeevent(e)}
                        />
                      </GridItem>
                      <GridItem>
                        <SelectField
                          value={scmfilter.region}
                          name="RegionSelector"
                          label="Region"
                          placeholder={"All Regions"}
                          noResultsMessage="No data found"
                          options={regions}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => filterchangeevent(e)}
                        />
                      </GridItem>
                      <GridItem>
                        <SelectField
                          value={scmfilter.zone}
                          name="ZoneSelector"
                          label="Zone"
                          placeholder={"All Zones"}
                          noResultsMessage="No data found"
                          options={zones}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => filterchangeevent(e)}
                        />
                      </GridItem>
                    </GridWrapper>
                  </FormGroup>
                  <>
                    <GridItem size={{ xl: '1', ss: '1', default: '1/1' }} className='formInputMaxWidth'>
                      <div className='formButtonGroup'>
                        <OutlinedButton size='200px' className='formDiscardButton' onClick={discardFilter} >Discard</OutlinedButton>
                        <FilledButton size='200px' onScroll={scrollToTop} onClick={serachFilter} className='formSaveButton' >Search</FilledButton>
                      </div>
                    </GridItem>
                  </>

                </div>
              </div>
            </GridItem>
          </div>
        </GridItem>
        <GridItem
          className={"cb-c-alerts-page__right-panel"}
          size={{ xs: "1/1", lg: "3/4" }}>

          {
            (loader === true) && (<div className='loader'></div>)
          }
          <div className='scm_contacts_table'>
            <table style={{ margin: "10px" }} className="linkedworkorder" >
              <tbody >
                <tr>
                  <th>Region</th>
                  <th>Zone</th>
                  <th>Name</th>
                  <th>Phone</th>
                  <th>Email</th>
                </tr>
                {
                  filterSCMModel && filterSCMModel.length > 0 &&
                  filterSCMModel.filter(x => x.role === 'SCM').map((scmdetails, index) => (

                    <tr key={index}>
                      <td>{scmdetails.region}</td>

                      <td onClick={() => { setIsOpen(true); setSelectedRow(scmdetails.zone); }}>{scmdetails.zone}</td>

                      <td>{scmdetails.name}</td>

                      <td>{scmdetails.phone}</td>

                      <td>{scmdetails.email}</td>

                      {/* <td>{scmdetails.notes}</td> */}

                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

        </GridItem>
      </GridWrapper>

      {
        siteCareManagerModel && selectedRow.length > 0 && siteCareManagerModel.length > 0 &&
        <Modal small={true} alert hideCloseButton open={isOpen} headingId="dialog-modal" style={{ display: 'flow', alignContent: "center", textAlign: '-webkit-center' }}>
          <div>
            <GridItem size="1" className="ln-u-hard" >
              <div>
                <b>Zone: {selectedRow ? selectedRow : ' '}</b><br />
                <b>Head of FM: {siteCareManagerModel.filter(x => x.role == 'LSCM' && x.zone == selectedRow)[0]
                  ? siteCareManagerModel.filter(x => x.role == 'LSCM' && x.zone == selectedRow)[0].name
                  : ''}</b><br />
                <b>Phone: {siteCareManagerModel.filter(x => x.role == 'LSCM' && x.zone == selectedRow)[0]
                  ? siteCareManagerModel.filter(x => x.role == 'LSCM' && x.zone == selectedRow)[0].phone
                  : ''}</b><br />
                <b>Email: {siteCareManagerModel.filter(x => x.role == 'LSCM' && x.zone == selectedRow)[0]
                  ? siteCareManagerModel.filter(x => x.role == 'LSCM' && x.zone == selectedRow)[0].email
                  : ''}</b><br />
              </div>
            </GridItem>
            <FilledButton style={{ float: 'right' }}
              onClick={() => setIsOpen(false)}>
              Close
    </FilledButton>
          </div>
        </Modal>
      }

    </>
  );
};

export default SiteCareManagersContacts;