import { Search } from '@jsluna/icons'
import weekendOnCallApi from '../../api/weekendOnCallApi'
import FormAlerts from "../Common/FormAlerts";
import { useApiClient } from '../../context/AppContext'
import { Modal, ModalHeading } from "@jsluna/modal"
import React, { useRef, useState, useEffect } from 'react'
import { FilledButton, OutlinedButton, TextInputField, TextInput } from '@jsluna/react'
import { WeekendFilter, WeekendOnCallModel } from '../../types/Entities/WeekendOnCallType'
import { GridWrapper, GridItem, SearchInput, CheckboxField, FormGroup, SelectField } from '@jsluna/react'
import WeekendOnCallApi from '../../api/weekendOnCallApi';
import '@jsluna/date-picker/react-dates-initialize'
import moment from 'moment'
import { SingleDatePicker } from '@jsluna/date-picker'
moment.locale('en-GB')


const WeekendCallContacts = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [errorAlert, setErrorAlert] = useState<false | string>(false);
  const [successAlert, setSuccessAlert] = useState<false | string>(false);


  const [pdate, setDate] = useState(null)
  const [focused, setFocused] = useState(false)

  const [loader, setLoader] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const [users, setUsers] = useState([{}])
  const [company, setCompany] = useState([{}]);
  const [detail, setDetail] = useState([{}]);
  const [weekendOnCallModel, setWeekendOnCallModel] = useState({} as WeekendOnCallModel);
  const [weekfilter, setWeekFilter] = useState({} as WeekendFilter);
  const apiClient = useApiClient();
  const scrollToTop = () => document.querySelector('div.ln-o-container.content')?.scrollTo(0, 0)

  const defaultWeekFilter = {
    wdate: moment(new Date()).format("YYYY-MM-DD"),
    contractId: '',
    detail: ''
  }

  useEffect(() => {
    const weekenddts = async () => { (await loadsweekendoncalldts(defaultWeekFilter)) }
    setLoader(true);
    weekenddts();
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, []);

  const loadsweekendoncalldts = async (weekendfilter: WeekendFilter): Promise<WeekendOnCallModel> => {
    return WeekendOnCallApi
      .getweekendoncalldetailsbydate(apiClient, weekendfilter)
      .then((response) => {
        setCompany(response.companyList);
        setDetail(response.detailsList);
        setWeekendOnCallModel(response);
      })
      .catch(error => {
        console.log(error)
        setErrorAlert('Something went wrong!')
        scrollToTop()
        return error;
      })
  }

  const filterchangeevent = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const element = event.target as HTMLSelectElement;
    if (element.id == "contractorseletor") {
      setWeekFilter(prevFilter => ({
        ...prevFilter,
        contractId: element.value
      }));
    }
    else if (element.id == "detailselector") {
      setWeekFilter(prevFilter => ({
        ...prevFilter,
        detail: element.value
      }));
    }
  }

  const datechangeevent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const element = event.target as HTMLInputElement;
    if (element.id == "dateinput") {
      setWeekFilter(prevFilter => ({
        ...prevFilter,
        wdate: element.value
      }));
    }
  }

  const serachFilter = async () => {
    const weekenddts = async () => { (await loadsweekendoncalldts(weekfilter)) }
    setLoader(true);
    weekenddts();
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  };

  const discardFilter = async () => {
    setWeekFilter(defaultWeekFilter)
    await loadsweekendoncalldts(defaultWeekFilter)
    // setWeekendOnCallModel({} as WeekendOnCallModel)
  }


  const clearAlerts = () => {
    setErrorAlert(false);
    setSuccessAlert(false);
  };

  return (
    <>
      <FormAlerts
        showError={errorAlert}
        showSuccess={successAlert}
        onClose={clearAlerts}
      />
      <GridWrapper>
        <GridItem size={{ xs: "1/1", lg: "1/4" }} >
          <div style={{ display: 'contents' }}
            className={"cb-c-alerts-page__filter-container--full-screen"}>
            <GridItem
              size={{ xs: "1/1", sm: "1/1" }}
              className="cb-c-alerts-page__left-panel alerts-page__filter">
              <div className="cb-c-alerts-page__options">
                <div>
                  <div>
                    <GridWrapper>
                      <GridItem size="1/1">
                        <h3>Filter Contacts</h3>
                      </GridItem>
                    </GridWrapper>
                  </div>
                  <FormGroup>
                    <GridWrapper>
                      <GridItem>
                        <input style={{ height: '50px' }} type="date" id="dateinput" value={weekfilter.wdate ? weekfilter.wdate : moment(new Date()).format("YYYY-MM-DD")}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => datechangeevent(e)} />
                      </GridItem>
                      <GridItem>
                        <SelectField
                          value={weekfilter.contractId}
                          name="contractorseletor"
                          label="Contractor Name"
                          placeholder={"All"}
                          noResultsMessage="No data found"
                          options={company}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => filterchangeevent(e)}
                        />
                      </GridItem>
                      <GridItem>
                        <SelectField
                          value={weekfilter.detail}
                          name="detailselector"
                          label="Detail"
                          placeholder={"All"}
                          noResultsMessage="No data found"
                          options={detail}
                          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => filterchangeevent(e)}
                        />
                      </GridItem>
                    </GridWrapper>
                  </FormGroup>
                  <>
                    <GridItem size={{ xl: '1', ss: '1', default: '1/1' }} className='formInputMaxWidth'>
                      <div className='formButtonGroup'>
                        <OutlinedButton size='200px' className='formDiscardButton' onClick={discardFilter}>Discard</OutlinedButton>
                        <FilledButton size='200px' onScroll={scrollToTop} onClick={serachFilter} className='formSaveButton' >Search</FilledButton>
                      </div>
                    </GridItem>
                  </>

                </div>
              </div>
            </GridItem>
          </div>
        </GridItem>
        <GridItem
          className={"cb-c-alerts-page__right-panel"}
          size={{ xs: "1/1", lg: "3/4" }}>
          <div>
            <h4>Site care on-call</h4>
            <div className="weekendCall_contacts_table">
              <table style={{ margin: "10px" }} className="linkedworkorder" >
                <tbody >
                  <tr>
                    <th></th>
                    <th className='tableName'>Name</th>
                    <th className='tablePhone'>Phone</th>
                    <th className='tableEmail'>Email</th>
                    <th className='tableNotes'>Notes</th>
                  </tr>
                  {
                    weekendOnCallModel.siteCareOnCallList && weekendOnCallModel.siteCareOnCallList.length > 0 &&
                    weekendOnCallModel.siteCareOnCallList.map((details, index) => (

                      <tr key={index}>

                        <td>{details.detail}</td>

                        <td>{details.name}</td>

                        <td>{details.phone}</td>

                        <td>{details.email}</td>

                        <td>{details.notes}</td>

                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
          {
            (loader === true) && (<div className='loader'></div>)
          }
          <h4>Contractor on-call</h4>
          <div className="weekendCall_contacts_table">
            <table style={{ margin: "10px" }} className="linkedworkorder" >
              <tbody >
                <tr>
                  <th>Contractor Name</th>
                  <th>Detail</th>
                  <th className='contractorOnCall_Level'>Level</th>
                  <th>Name</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Notes</th>
                </tr>
                {
                  weekendOnCallModel.companyOnCallList && weekendOnCallModel.companyOnCallList.length > 0 &&
                  weekendOnCallModel.companyOnCallList.map((details, index) => (

                    <tr key={index}>
                      <td>{details.companyName}</td>
                      <td>{details.detail}</td>
                      <td>{details.level}</td>
                      <td>{details.name}</td>
                      <td>{details.phone}</td>
                      <td>{details.email}</td>
                      <td>{details.notes}</td>

                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </GridItem>
      </GridWrapper>

    </>
  );
};

export default WeekendCallContacts;