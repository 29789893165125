import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import FormAlerts from "../Common/FormAlerts";
import { Modal, ModalHeading } from "@jsluna/modal";
import { GridWrapper, GridItem } from "@jsluna/grid";
import { TextArea, TextAreaField } from "@jsluna/form";
import { CancelButton, TextButton } from "@jsluna/button";
import { Accordion, AccordionItem } from "@jsluna/accordion";
import { FilledButton, OutlinedButton, FormGroup, BreadcrumbsWrapper, BreadcrumbsItem, Body1, } from "@jsluna/react";

import Circle from "../../assets/icons/Circle.svg";
import usernameApi from "../../api/riskWorkordersApi";
import { useApiClient } from "../../context/AppContext";
import riskWorkordersApi from "../../api/riskWorkordersApi";
import InccidentManagementApi from '../../api/IncidentManagementApi'
import { postComment, Workorders } from "../../types/Entities/Workorders";
import { IncidentManagements, IncidentWorkorders } from '../../types/Entities/IncidentManagement'
import ConfirmationModal from "../Common/ConfirmationModal";
import IncidentManagementApi from "../../api/IncidentManagementApi";
import Timer from "../Common/Timer";
import IncideDetailsTable from "../Common/IncidentDetailsTable";
import CommentsComponent from '../Common/CommentsComponent'


const IncidentWorkordersDetails = () => {

  const apiClient = useApiClient();
  const [timer, setTimer] = useState<number>(0)
  const [isOpen, setIsOpen] = useState(false);
  const [loader, setLoader] = useState(true)
  const myFormRef = useRef<HTMLFormElement>(null);
  const [incidentCompleteButton, setIncidentComplpeteButton] = useState(false)
  const [rootCauseButton, setRootCauseButton] = useState(false)
  const [accordionexpand, setaccordionexpand] = useState<boolean>(true);
  const [workorderdetails, setworkorderdetails] = useState<IncidentManagements>();
  const [comments, setComments] = useState<postComment[]>([]);
  const [newComment, setNewComment] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [errorAlert, setErrorAlert] = useState<false | string>(false);
  const [successAlert, setSuccessAlert] = useState<false | string>(false);
  const [loggedInUserName, setloggedInUserName] = useState<string>("");
  const [latestComment, setLatestComment] = useState<postComment | null>(null);
  const [latestCommentState, setLatestCommentState] = useState("")
  const [dateCreatedState, setDateCreatedState] = useState("")

  const [modalState, setModalState] = useState<"complete" | "discard" | "save" | "rootcause" | false>(false);
  const [incidentManagementDetails, setIncidentManagementDetails] = useState<IncidentManagements[]>()
  const [incidentWorkorders, setIncidentWorkorders] = useState<IncidentWorkorders>()
  const [incidentWorkordersTemp, setIncidentWorkordersTemp] = useState<IncidentManagements>()


  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 200000);
    return () => clearInterval(interval);
  }, []);

  const fetchData = async () => {
    setLoader(true)
    loadworkorders();
    scrollToTop();
    setloggedInUserName(usernameApi.getUsername(apiClient) as string);
  };
  const handleDiscardForm = () => {
    setModalState(false);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const clearAlerts = () => {
    setErrorAlert(false);
    setSuccessAlert(false);
  };
  const handleCompleteModalSave = () => {
    clearAlerts();
    setLoader(true)
    IncidentManagementApi
      .incidentComplete(apiClient, window.location.pathname.split("/")[2])
      .then((response) => {
        scrollToTop();
        setSuccessAlert("Status updated Successfully");
        setIncidentComplpeteButton(false)
        setRootCauseButton(true)
        setLoader(false)
      })
      .catch((error) => {
        scrollToTop();
        setErrorAlert("Failed to update status");
        setLoader(false)
      });
    setModalState(false)
  };
  const handleRootCauseModalSave = (value?: string) => {
    clearAlerts();
    setLoader(true)
    const womanagerreview = {
      WorkOrderNumber: window.location.pathname.split("/")[2],
      Comments: value
    }
    IncidentManagementApi
      .rootCausePost(apiClient, womanagerreview)
      .then((response) => {
        scrollToTop();
        if (response.ok) {
          setSuccessAlert("Status updated Successfully");
          setNewComment("");
          loadworkorders();
          scrollToTop();
          setRootCauseButton(false)
        } else {
          setErrorAlert("Failed to update status");
        }
        setLoader(false)
      })
      .catch((error) => {
        scrollToTop();
        setErrorAlert("Failed to update status");
        setLoader(false)
      });
    setModalState(false)
  }

  const loadworkorders = async () => {
    return IncidentManagementApi
      .getincidentsbywo(apiClient, window.location.pathname.split("/")[2])
      .then((response) => {
        console.log(response)
        setIncidentWorkorders(response)
        // alert(response.workOrdersDetails.timer)
        setTimer(response.workOrdersDetails.timer)
        response.workOrdersDetails.status === "Open" ? setIncidentComplpeteButton(true) : setIncidentComplpeteButton(false)
        response.workOrdersDetails.status === "Root Cause Analysis" ? setRootCauseButton(true) : setRootCauseButton(false)
        setLoader(false)
        return response;
      })
      .catch(error => {
        setErrorAlert('Something went wrong!')
        setLoader(false)
      })
  };


  const scrollToTop = () => {
    document.querySelector("div.ln-o-container.content")?.scrollTo(0, 0);
  }

  const addComments = () => {
    clearAlerts();
    if (!newComment || newComment.trim().length === 0) {
      scrollToTop();
      setErrorAlert("Comment cannot be empty");
      return false;
    }
    setLoader(true)
    let newguid = crypto.randomUUID();
    const newCommentObj = {
      id: newguid,
      workOrderNumber: window.location.pathname.split("/")[2],
      commentedBy: loggedInUserName,
      commentedDate: moment().toDate(),
      comments: newComment,
    };

    const logNote = {
      WorkOrderNumber: window.location.pathname.split("/")[2],
      Comments: newComment
    }
    const updatedComments = [newCommentObj, ...comments];
    setComments(updatedComments);
    setLatestComment(newCommentObj);

    IncidentManagementApi
      .woLogNote(apiClient, logNote)
      .then((response) => {
        setLoader(false)
        scrollToTop();
        if (response.ok) {
          setSuccessAlert("Comment added Successfully");
          setNewComment("");
          loadworkorders();
        } else {
          scrollToTop();
          setErrorAlert("Failed to add comment");
          setComments((prevComments) =>
            prevComments.filter((comment) => comment.id !== newguid)
          );
        }
      })
      .catch((error) => {
        setLoader(false)
        scrollToTop();
        setErrorAlert("Failed to add comment");
        setComments((prevComments) =>
          prevComments.filter((comment) => comment.id !== newguid)
        );
      });
  };

  const setTimerCount = (count: number): React.ReactNode => {
    alert(count)
    if (count) {
      return Timer(count);
    }
    return null;
  };


  return (
    <>
      <FormAlerts
        showError={errorAlert}
        showSuccess={successAlert}
        onClose={clearAlerts}
      />
      <ConfirmationModal
        showModal={modalState}
        onClose={() => setModalState(false)}
        onSave={handleRootCauseModalSave}
        onDiscard={handleDiscardForm}
        onComplete={handleCompleteModalSave}
      />
      <div>
        <BreadcrumbsWrapper narrow className="breadcrumb">
          <BreadcrumbsItem>
            <Link to="/incident-managementfilter">Incident Management</Link>
          </BreadcrumbsItem>
          <BreadcrumbsItem active >
            {window.location.pathname.split("/")[2]}
          </BreadcrumbsItem>
        </BreadcrumbsWrapper>
      </div>
      {
        <Modal small={true} alert hideCloseButton open={isOpen} headingId="dialog-modal" style={{ display: 'flow', alignContent: "center", textAlign: '-webkit-center' }}>
          <div style={{ margin: '10px 28%' }}>
            {/* <GridItem size="1" className="ln-u-hard" >
                <div
                style={{
                  padding: "0.5rem 1rem",
                  border: "1px solid #d8d8d8",
                  backgroundColor: "#d8d8d8",
                  maxHeight: "160px",
                  margin:'0px 0% 0px -63%',
                  width: '226%',
                }}
                >
                  <b>Date Created: </b>{" "}
                  {dateCreatedState
                    ? moment(dateCreatedState).format(
                      "DD MMM YY, HH:mm"
                    )
                    : ""}
                </div>
              </GridItem> */}
            {/*<GridItem size="1" className="ln-u-hard" children={undefined}>*/}
            {/*  </GridItem>*/}
            <div
              style={{
                padding: "0.5rem 1rem",
                border: "1px solid #d8d8d8",
                backgroundColor: "#d8d8d8",
                margin: '0px 0% 0px -63%',
                width: '226%',
              }}
            >
              <GridItem size="1" className="ln-u-hard" style={{ width: '100%' }}>
                <b>Latest comments : </b>
              </GridItem>
            </div>
            <GridItem size="1" className="ln-u-hard" style={{ width: '125%' }}>
              <TextArea
                rows={5}
                name="textarea-1"
                value={latestCommentState}
                disabled
                style={{
                  opacity: "1",
                  background: "none",
                  border: "1px solid #d8d8d8",
                  paddingLeft: '10px',
                  margin: ' 0 px 20%',
                  width: '180%',
                  marginLeft: '-50%'

                }}
              />
            </GridItem>
            <TextButton
              fullWidth
              id="maybe-later-button"
              className="feedback-closeButton"
              onClick={handleClose}
              disabled={disabledButton}
            >
              Close
            </TextButton>
          </div>
        </Modal>
      }
      {incidentWorkorders?.workOrdersDetails
        &&
        <div className="incidents_details">
          <IncideDetailsTable table={incidentWorkorders.workOrdersDetails} />
        </div>
      }
      <Accordion
        defaultOpen={accordionexpand}
        title="Linked Work Orders"
        multipleOpen
        titleElement="h3"
      >
        <div className="linked_work_orders_accordian">
          <table className="linkedworkorder" >
            <tbody >
              <tr>
                <th className="head">Work Order</th>
                <th>Assigned Contractor</th>
                <th>Category,sub</th>
                <th>Problem Type</th>
                <th>WO Status</th>
                <th className="head">ETA</th>
                <th className="head1">Date Modified</th>
                <th>Latest Comments</th>
              </tr>

              {incidentWorkorders?.linkedWorkOrdersDetails && incidentWorkorders?.linkedWorkOrdersDetails.map((wo, index) => (
                <tr key={index} onClick={() => { setIsOpen(true); setLatestCommentState(wo.latestComment); setDateCreatedState(wo.eta) }}>
                  <td className="head">{wo?.linkedWorkOrderNumber}</td>

                  <td>{wo?.provider ? wo.provider : ""}</td>

                  <td>{wo?.categorySubCategory}</td>

                  <td>{wo?.problemType}</td>

                  <td className="head">{wo?.woStatus}</td>

                  <td>
                    {wo?.eta
                      ? moment(wo.eta).format("DD MMM YY, HH:mm")
                      : ""}
                  </td>

                  <td className="head1">
                    {wo?.dateModified
                      ? moment(wo.dateModified).format("DD MMM YY, HH:mm")
                      : ""}
                  </td>

                  <td>
                    {wo?.latestComment
                      ? wo.latestComment.substring(0, 50) + `${wo.latestComment.length > 50 ? '...' : ""}`
                      : ""}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Accordion>
      {
        (loader === true) && (<div className='loader' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '20%' }}></div>)
      }
      <div
        className="formButtonGroup"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100px",
        }}
      >
        <FilledButton fullWidth onClick={() => { setModalState("complete") }} disabled={!incidentCompleteButton}>
          Incident Complete
          </FilledButton>
        <OutlinedButton
          fullWidth
          onClick={() => setModalState("rootcause")}
          disabled={!rootCauseButton}
        >
          Root Cause
          </OutlinedButton>
      </div>
      <div>
        {/* <FormGroup ref={myFormRef}> */}
        <TextArea
          name="text-area-field-1"
          placeholder="What's new?"
          value={newComment}
          onChange={(e: any) => setNewComment(e.target.value)}
          disabled={isSubmitting}
          onFocus={() => clearAlerts()}
        />
        {/* </FormGroup> */}
        <FilledButton
          fullWidth
          id="submit-button"
          className="feedback-submitButton ln-u-margin-bottom*2"
          onClick={addComments}
          disabled={disabledButton}
        >
          Add Comments
        </FilledButton>
        <div
          style={{ margin: "10px", padding: "20px", backgroundColor: "white", overflow: 'scroll' }}
        >
          {/* <CommentsComponent comments={incidentWorkorders?.workOrdersHistory ? incidentWorkorders?.workOrdersHistory : []} /> */}
          {incidentWorkorders?.workOrdersHistory && incidentWorkorders?.workOrdersHistory.map((comment, index) => (
            <div
              className='workorder_updates'
            // style={{
            //   display: "grid",
            //   gridTemplateColumns: "1fr 8fr",
            //   gridGap: "10px",
            //   borderLeft: "3px solid rgb(193 182 182)",
            //   width: "50%",
            //   marginLeft: "50px",
            //   marginBottom: "-5%",
            // }}
            >
              <div style={{ margin: "10px", marginLeft: "-10px" }}>
                <div>
                  <svg height="35" width="15">
                    <line x1="2" y1="35" x2="2" y2="0"></line>
                  </svg>
                </div>
                <img src={Circle} alt="Circle" height="20" width="20"></img>
                <div>
                  <svg height="100%" width="15">
                    <line x1="2" y1="100%" x2="2" y2="0"></line>
                  </svg>
                </div>
              </div>
              <div
                key={index}
                style={{
                  margin: "10px",
                  marginTop: "50px",
                  width: "200%",
                  height: "50%",
                }}
              >
                <div
                  style={{
                    marginBottom: "5%",
                  }}
                >
                  <div
                    style={{
                      padding: "1rem",
                      border: "1px solid #d8d8d8",
                      backgroundColor: "#d8d8d8",
                    }}
                  >
                    <b>{comment?.eventCreatedBy}</b> commented on{" "}
                    {comment.dateCreated
                      ? moment(comment.dateCreated).format("DD MMM YY,HH:mm")
                      : ""}{" "}
                    <br />
                  </div>
                  <div style={{ padding: "1rem", border: "1px solid #d8d8d8" }}>
                    {comment.comments}
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div
            className='workorder_updates'
            style={{
              width: "max-content",
            }}
          >
            <img
              src={Circle}
              alt="Circle"
              height="20"
              width="20"
              style={{ marginLeft: "-10px" }}
            ></img>
            <div style={{ paddingTop: "2%" }}>
              Workorder Created on{" "}
              {incidentWorkorders?.workOrdersDetails
                ? moment(incidentWorkorders?.workOrdersDetails.dateCreated).format(
                  "DD MMM YY, HH:mm"
                )
                : ""}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default IncidentWorkordersDetails;