import React, { useRef, useState, useEffect } from "react";
import {
  GridWrapper,
  GridItem,
  SearchInput,
  FormGroup,
  BreadcrumbsWrapper,
  BreadcrumbsItem,
} from "@jsluna/react";
import Circle from "../../assets/icons/Circle.svg";
import { postComment, Workorders } from "../../types/Entities/Workorders";
import { TextArea, TextAreaField } from "@jsluna/form";
import { FilledButton, TextButton, CancelButton } from "@jsluna/button";
import { Link } from "react-router-dom";
import { useApiClient } from "../../context/AppContext";
import riskWorkordersApi from "../../api/riskWorkordersApi";
import apiClients from "../../api/ApiClient";
import FormAlerts from "../Common/FormAlerts";
import ConfirmationModal from "../Common/ConfirmationModal";
import moment from "moment";
import usernameApi from "../../api/riskWorkordersApi";
import { Body1 } from '@jsluna/typography'


import CommentsComponent from '../Common/CommentsComponent'

const WorkOrderDetails: React.FC = () => {
  const textAreaLength = 150
  const [comments, setComments] = useState<postComment[]>([]);

  const [errorAlert, setErrorAlert] = useState<false | string>(false);
  const [successAlert, setSuccessAlert] = useState<false | string>(false);
  const [loader, setLoader] = useState(false);
  const [workorderdetails, setworkorderdetails] = useState<Workorders>();

  const [newComment, setNewComment] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [loggedInUserName, setloggedInUserName] = useState<string>("");
  const [modalState, setModalState] = useState<"save" | "discard" | false>(
    false
  );
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [initialStatus, setInitialStatus] = useState<string>("");
  const myFormRef = useRef<HTMLFormElement>(null);
  const apiClient = useApiClient();

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 200000);
    return () => clearInterval(interval);
  }, []);

  const fetchData = async () => {
    loadworkorderdetails();
    setloggedInUserName(usernameApi.getUsername(apiClient) as string);
  };

  const loadworkorderdetails = async () => {
    setLoader(true);
    const details = await loadworkorders();
    setworkorderdetails(details);
    setInitialStatus(details.fM_WOStatus as string);
    console.log(initialStatus);
    const commentsData = await getComments();
    setLoader(false);
    // scrollToTop();
  };

  const getComments = async () => {
    try {
      const res = await riskWorkordersApi.gethelpdeskcommentsbywo(
        apiClient,
        window.location.pathname.split("/")[2]
      );
      console.log(res);
      setComments(res);
      return res;
    } catch (error) {
      setErrorAlert("Failed to load comments");
      return comments;
    }
  };

  const clearAlerts = () => {
    setErrorAlert(false);
    setSuccessAlert(false);
  };

  const handleDiscardForm = () => {
    setModalState(false);
    clearAlerts();
    setSelectedStatus(initialStatus);
  };

  const loadworkorders = async (): Promise<Workorders> => {
    return riskWorkordersApi
      .getriskworkordersbywo(apiClient, window.location.pathname.split("/")[2])
      .then((response) => {
        return response;
      });
  };

  const scrollToTop = () =>
    document.querySelector("div.ln-o-container.content")?.scrollTo(0, 0);

  const handleStatus = () => {
    clearAlerts();
    let tempStatus = workorderdetails as Workorders;
    tempStatus.fM_WOStatus = selectedStatus;
    setworkorderdetails(tempStatus);
    setLoader(true);
    updateFM_WOStatus(tempStatus);
    setLoader(false);
  };

  const addComments = () => {
    setLoader(true)
    clearAlerts();
    if (!newComment || newComment.trim().length === 0) {
      scrollToTop();
      setErrorAlert("Comment cannot be empty");
      setLoader(false)

      return false;
    }
    let newguid = crypto.randomUUID();
    const newCommentObj = {
      id: newguid,
      workOrderNumber: window.location.pathname.split("/")[2],
      commentedBy: loggedInUserName,
      commentedDate: moment().toDate(),
      comments: newComment,
    };
    const updatedComments = [newCommentObj, ...comments];

    setComments(updatedComments);
    riskWorkordersApi
      .addhelpdeskcomments(apiClient, newCommentObj)
      .then((response) => {
        clearAlerts();
        scrollToTop();
        setSuccessAlert("Comment added Successfully");
        setNewComment("");
      })
      .catch((error) => {
        scrollToTop();
        setErrorAlert("Failed to add comment");
        setComments((prevComments) =>
          prevComments.filter((comment) => comment.id !== newguid)
        );
      });
    setLoader(false);
  };

  const updateFM_WOStatus = (tempStatus: Workorders) => {
    clearAlerts();
    setModalState(false);

    return riskWorkordersApi
      .updatefmworkorderstatus(apiClient, tempStatus)
      .then((response) => {
        fetchData();
        scrollToTop();
        setSuccessAlert("Status updated Successfully");
        return response;
      })
      .catch((error) => {
        scrollToTop();
        setErrorAlert("Something went wrong");
        return error;
      });
  };

  const changeComment = (e: any) => {
    setNewComment(e)
  }
  return (
    <>
      <FormAlerts
        showError={errorAlert}
        showSuccess={successAlert}
        onClose={clearAlerts}
      />
      <ConfirmationModal
        showModal={modalState}
        onClose={handleDiscardForm}
        onSave={handleStatus}
        onDiscard={handleDiscardForm}
        onComplete={handleStatus}
      />

      <div>
        <br />

        <BreadcrumbsWrapper narrow className="breadcrumb">
          <BreadcrumbsItem>
            <Link to="/risk-workordersfilter">RiskWorkorders</Link>
          </BreadcrumbsItem>
          <BreadcrumbsItem active backToParent>
            {window.location.pathname.split("/")[2]}
          </BreadcrumbsItem>
        </BreadcrumbsWrapper>
      </div>
      <div className='workorder_details_table'>
        <table className="workorderdetails">
          <tbody style={{ border: "1 px black" }}>
            <tr>
              <th>Work Order</th>
              <td>{workorderdetails?.workOrderNumber}</td>
              <th>Assigned Contractor</th>
              <td>
                {workorderdetails?.providerName
                  ? workorderdetails?.providerName
                  : ""}
              </td>
            </tr>
            <tr>
              <th>Work Order Status</th>
              <td>
                {workorderdetails?.woStatus ? workorderdetails?.woStatus : ""}
              </td>
              <th>Date Created</th>
              <td>
                {workorderdetails?.dateCreated
                  ? moment(workorderdetails?.dateCreated).format(
                    "DD MMM YY, HH:mm"
                  )
                  : ""}
              </td>
            </tr>
            <tr>
              <th>Date Last Modified</th>
              <td>
                {workorderdetails?.dateModified
                  ? moment(workorderdetails?.dateModified).format(
                    "DD MMM YY, HH:mm"
                  )
                  : ""}
              </td>
              <th>Fix Due Date</th>
              <td>
                {workorderdetails?.fixDueDate
                  ? moment(workorderdetails?.fixDueDate).format("DD MMM YY, HH:mm")
                  : ""}
              </td>
            </tr>
            <tr>
              <th>Category</th>
              <td>
                {workorderdetails?.category ? workorderdetails?.category : ""}
              </td>
              <th>Sub Category</th>
              <td>
                {workorderdetails?.subCategory
                  ? workorderdetails?.subCategory
                  : ""}
              </td>
            </tr>

            <tr>
              <th>Problem Type</th>
              <td>
                {workorderdetails?.problemType
                  ? workorderdetails?.problemType
                  : ""}
              </td>
              <th>Site</th>
              <td>
                {workorderdetails?.siteName ? workorderdetails?.siteName : ""}  {workorderdetails?.siteDesc ? workorderdetails?.siteDesc : ""}
              </td>
            </tr>

            <tr>
              <th>Asset </th>
              <td>
                {workorderdetails?.assetNumber
                  ? workorderdetails?.assetNumber
                  : ""}
              </td>
              <th>Asset Type</th>
              <td>
                {workorderdetails?.assetTypeName}
              </td>
            </tr>

            <tr>
              <th colSpan={1} rowSpan={(workorderdetails && workorderdetails.problemDescription)
                ? Math.ceil(workorderdetails.problemDescription.length / textAreaLength)
                : 1} >
                Work Order Problem Description
            </th>
              <td colSpan={3} >
                <TextArea rows={(workorderdetails && workorderdetails.problemDescription)
                  ? Math.ceil(workorderdetails.problemDescription.length / textAreaLength)
                  : 1}
                  name="textarea-1"
                  value={workorderdetails?.problemDescription}
                  disabled
                  style={{ opacity: "1", backgroundColor: "#f0f0f0", border: 'none', background: "#f0f0f0" }}
                />
              </td>
            </tr>
            <tr></tr>
            <tr></tr>
            <tr></tr>
            <tr></tr>
            <tr></tr>
            <tr></tr>
            <tr>
              <th rowSpan={2} colSpan={1}>
                Work Order Latest Comment{" "}
              </th>
              <td colSpan={3}>
                <TextArea rows={2}
                  name="textarea-1"
                  value={workorderdetails?.latestComment}
                  disabled
                  style={{ opacity: "1", backgroundColor: "#f0f0f0", border: 'none', background: "#f0f0f0" }}
                />
              </td>
            </tr>
            <tr></tr>
            <tr></tr>
            <tr></tr>
            <tr>
              <th>Risk Score</th>
              <td>
                {Math.round(
                  workorderdetails?.risK_SCORE ? workorderdetails?.risK_SCORE : 0
                )}
              </td>
              <th>Client</th>
              <td>
                {workorderdetails?.clientName ? workorderdetails?.clientName : ""}
              </td>
            </tr>
            <tr>
              <th>Customer Location</th>
              <td>{workorderdetails?.scorE_CUSTOMER_LOCATION != undefined && workorderdetails?.scorE_CUSTOMER_LOCATION > 0 ? "Y" : "N"}</td>
              <th>Critical Asset</th>
              <td>{workorderdetails?.scorE_CRITICAL_DOWN != undefined && workorderdetails?.scorE_CRITICAL_DOWN > 0 ? "Y" : "N"}</td>
            </tr>
            <tr>
              <th>Repeat Asset Fail</th>
              <td>{workorderdetails?.scorE_REPEAT_FAIL != undefined && workorderdetails?.scorE_REPEAT_FAIL > 0 ? "Y" : "N"}</td>
              <th>Aged Work Order</th>
              <td>{workorderdetails?.scorE_AGED_WO != undefined && workorderdetails?.scorE_AGED_WO > 0 ? "Y" : "N"}</td>
            </tr>
            <tr>
              <th> ETA Changes</th>
              <td>{workorderdetails?.scorE_ETA_CHANGES != undefined && workorderdetails?.scorE_ETA_CHANGES > 0 ? "Y" : "N"}</td>
              <th>ETA Historic</th>
              <td>{workorderdetails?.scorE_ETA_OVER != undefined && workorderdetails?.scorE_ETA_OVER > 0 ? "Y" : "N"}</td>
            </tr>

            <tr>
              <th>Status</th>
              <td>
                <div className='change_status_and_submit'
                  style={{ display: 'flex', alignItems: 'center', placeContent: 'center' }}>
                  <select onFocus={() => clearAlerts()}
                    style={{ width: "50%", padding: "15px", marginRight: '5px' }}
                    value={selectedStatus ? selectedStatus : workorderdetails?.fM_WOStatus}
                    onChange={(e: any) => { setSelectedStatus(e.target.value) }}
                  >
                    <option value="Open">Open</option>
                    <option value="In Progress">In Progress</option>
                    <option value="Closed">Closed</option>
                  </select>


                  <FilledButton
                    id="submit-button"
                    className="feedback-submitButton ln-u-margin-bottom*2"
                    onClick={() => setModalState("save")}
                    style={{ width: '50%', padding: '12px', marginTop: '15px' }}
                    disabled={disabledButton}
                  >
                    Save
                </FilledButton>
                </div>
              </td>


            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <FormGroup ref={myFormRef}>
          <TextAreaField
            name="text-area-field-1"
            placeholder="What's new?"
            value={newComment}
            onChange={(e: any) => changeComment(e.target.value)}
            disabled={isSubmitting}
            onFocus={() => clearAlerts()}
          />
        </FormGroup>
        <FilledButton
          fullWidth
          id="submit-button"
          className="feedback-submitButton ln-u-margin-bottom*2"
          onClick={addComments}
          disabled={disabledButton}
        >
          Add Comments
        </FilledButton>
        <div
          style={{ margin: "10px", padding: "20px", backgroundColor: "white", overflow: 'scroll' }}
        >
          <CommentsComponent comments={comments} />
          <div className='workorder_updates' style={{ width: "max-content", }}>
            <img
              src={Circle}
              alt="Circle"
              height="20"
              width="20"
              style={{ marginLeft: "-10px" }}
            ></img>
            <div style={{ paddingTop: '2%' }}>
              Workorder Created on{" "}
              {workorderdetails?.dateCreated
                ? moment(workorderdetails?.dateCreated).format(
                  "DD MMM YY, HH:mm"
                )
                : ""}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkOrderDetails;
